import '../../App.css'
import '../Description'
import Description from '../Description'

function About() {
  return(
    <>
      <Description/>
    </>
  )
}

export default About;